import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjQ3NzA3LCJleHAiOjE4OTM0MjcyMDAsImlhdCI6MTcyMjQwOTY0N30=.iY2LhEaaYIdMC8MOUVeJqnyo/v4/hBWY+zeueTIlmjI=',
    loading: null,
  },
  getters: {
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
  },
  modules: {
  }
})
