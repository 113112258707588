<template>
	<div class="index">
		<div class="search" @click="toSearch">
			<img class="search-button" src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/index/search.png" alt="">
			<span>输入剧名搜索</span>
		</div>

		<div class="banner">
			<swiper class="swiper">
				<swiper-slide v-for="item in banner" :key="item.id"><img :src="item.image" @click="toDetail" :data-id="item.targetId" alt=""></swiper-slide>
			</swiper>
		</div>

		<div class="toolbar-list">
			<img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/index/list1.png" alt="" @click="toList" data-type="11">
			<img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/index/list2.png" alt="" @click="toList" data-type="12">
			<img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/index/list3.png" alt="" @click="toList" data-type="13">
			<img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/index/list4.png" alt="" @click="toList" data-type="14">
		</div>

		<div class="hot">
			<div class="hot-name">本周热门</div>
			<div class="teleplay-list" v-if="hotList.length > 0">
				<div class="teleplay" v-for="item in hotList" :key="item.id" @click="toDetail" :data-id="item.id">
					<div class="img-container"><img :src="item.cover" alt=""></div>
					<div class="title">{{item.name}}</div>
				</div>
			</div>
			<div class="empty" v-else>
				<img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogram/theater/index/empty4.png" />
			</div>
		</div>

		<div class="rank" ref="rank">
			<div class="rank-name">最新好剧</div>
			<swiper class="swiper" :options="swiperOption2" ref="mySwiper">
				<swiper-slide v-for="(item, index) in newList" :key="index" :data-id="item.id" class="teleplay">
					<template v-if="videoIndex == index">
						<video-player class="video":ref="`videoRef${index}`" :options="playerOptions" :poster="item.cover" playsinline webkit-playsinline x5-playsinline @click="videoClick" @playing="videoPlaying"></video-player>
						<div class="cover-view" v-if="videoCoverView" :data-id="item.id" @click="toDetail"></div>
					</template>
					<div class="img-container" v-else><img :src="item.cover" alt=""></div>
				</swiper-slide>
			</swiper>
		</div>

		<div class="all">
			<div class="title">全部好剧</div>
			<div class="teleplay" v-if="list.length > 0" v-for="(item, index) in list" :key="index" :data-id="item.id" @click="toDetail">
				<div class="img-container"><img :src="item.cover" alt=""></div>
				<div class="title">{{item.name}}</div>
			</div>
			<div class="empty" v-else>
				<img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogram/theater/index/empty4.png" />
			</div>
		</div>

		<div class="watch" ref="watch" @click="toWelfare" v-if="welfareImgShow">
			<img :src="welfareImgUrl">
		</div>

		<div class="continue" ref="continue" v-if="continueShow && last.id > 0" :data-id="last.id" @click="toDetail" style="display: none;">
			<div class="img-container"><img class="continue-cover" :src="last.cover" alt=""></div>
			<div class="continue-button">继续观看</div>
			<img class="continue-play" src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/index/play.png" alt="">
		</div>
	</div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
	name: 'Index',
	components: {
		swiper,
		swiperSlide,
	},
	props: {
		changeTabFunc: Function,
	},
	data () {
		return {
			swiperOption: {
				loop: true,
				slidesPerView: 'auto',
			},
			swiperOption2: {
				initialSlide: 0,
				slidesPerView: '4.1',
				spaceBetween: 10,
				centeredSlides: true,
				loop: false,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
			},
			enterTime: 0,
			banner: [],
			list: [],
			videoIndex: 0,
			current: 1,
			playerOptions: {
				//muted: true,
				loop: true,
				autoplay: false,
				controls: true,
				playsinline: true,
				sources: [
					{
						src: '',
						type: 'video/mp4'
					}
				],
				controlBar: {
					volumeMenuButton: false
				}
			},
			imageHide: false,
			last: {
				id: 0,
				name: '',
				cover: '',
				episode: 0,
			},
			isLoading: false,
			hasMoreData: true,
			pageNumber: 1,
			teleplayList: [],
			hotList: [],
			newList: [],
			noticeShow: false,
			searchShow: false,
			welfareImgShow: false,
			welfareImgUrl: '',
			continueShow: false,
			videoCoverView: false,
			scrollListener: null,
			isScrolling: null,
			path: '',
		}
	},
	activated() {
		console.log('activated activated activated');
		this.$util.setTitle('剧有量短剧');
		this.loadUserLastRecord();
		document.addEventListener('scroll', this.scrollListener);
		this.path = '/index' + window.location.search;
		this.enterTime = new Date().getTime();
	},
	deactivated() {
		console.log('deactivated deactivated deactivated');
		if (this.scrollListener) {
			document.removeEventListener('scroll', this.scrollListener);
		}
		const leaveTime = new Date().getTime();
		const stayTime = leaveTime - this.enterTime;
		this.$util.pageStopover(this.path, Math.floor(stayTime / 1000));
	},
	mounted() {
		console.log('ua', navigator.userAgent)
		console.log('mounted mounted mounted')
		let that = this;
		const swiperInstance = this.$refs.mySwiper.swiper;
		console.log('Current Slide Index:', swiperInstance.activeIndex);
		this.videoIndex = swiperInstance.activeIndex;
		swiperInstance.on('slideChange', () => {
			console.log('切换到了新的 Slide！');
			// 获取当前活动 SwiperSlide 的索引
			const currentIndex = swiperInstance.activeIndex;
			that.videoIndex = currentIndex;
			console.log('Current Slide Index:', currentIndex);
			that.videoCoverView = false;
			if (that.newList.length) {
				that.playerOptions.sources[0].src = that.newList[currentIndex].playUrl;
				that.$nextTick(() => {
					//that.videoPlay();
					const element = that.$refs.rank;
					if (that.isElementInViewport(element)) {
						console.log('触发播放');
						that.videoPlay();
					}
				});
			}
		});
		that.$util.showLoading()
		that.$util.setTitle('剧有量短剧');
		that.$util.axios.get('api/applet/v2/playlet/banner', {}).then(function (res) {
			console.log('res', res)
			if (res.code == 100) {
				that.banner = res.data.list;
			}
		}).catch(function (error) {
			console.log('banner获取失败', error)
		});
		that.$util.axios.get('api/applet/v2/playlet/list?sort=2&limit=10', {}).then(function (res) {
			console.log('list', res)
			if (res.code == 100) {
				that.hotList = res.data.list;
			}
		}).catch(function (error) {
			console.log('列表获取失败', error)
		});
		that.$util.axios.get('api/applet/v2/playlet/list?sort=3&limit=3&needFirst=1', {}).then(function (res) {
			if (res.code == 100) {
				let list = [];
				list.push(...res.data.list);
				list.push(...res.data.list);
				list.push(...res.data.list);
				that.newList = list;
				if (list.length) {
					that.playerOptions.sources[0].src = that.newList[3].playUrl;
					that.$nextTick(() => {
						const swiperInstance = that.$refs.mySwiper.swiper;
						const index = 3;
						swiperInstance.slideTo(index);
					});
				}
			}
		}).catch(function (error) {
			console.log('列表获取失败', error)
		});
		that.$util.axios.get('api/applet/v1/playlet/config', {}).then(function (res) {
			that.noticeShow = res.data.noticeShow;
			that.searchShow = res.data.searchShow;
			that.welfareImgShow = res.data.welfareImgShow;
			that.welfareImgUrl = res.data.welfareImgUrl;
			that.continueShow = res.data.continueShow;
		}).catch(function (error) {
			console.log('config获取失败', error)
		});
		that.loadListData(true);
		that.loadUserLastRecord();
		that.$util.wechatConfig();
		that.path = '/index' + window.location.search;
		that.enterTime = new Date().getTime();
		that.$util.accessLog(that.path, {});
	},
	created() {
		console.log('created created created')
		let that = this;
		// document.addEventListener("WeixinJSBridgeReady", () => {
		// 	console.log('WeixinJSBridgeReady event triggered in Vue created hook!');
		// 	//that.videoPlay();
		// }, false);
		// document.addEventListener('touchstart', function() {
		// 	console.log('touchstart');
		// 	//that.videoPlay();
		// })
		const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
		if (isIOS) {
			console.log('当前设备为 iOS');
		} else {
			console.log('当前设备不是 iOS');
		}

		that.scrollListener = () => {
			if (!that.imageHide) {
				const watch = that.$refs.watch;
				if (watch) {
					watch.style.transition = 'transform 0.5s ease';
					watch.style.transform = 'translateX('+watch.clientWidth+'px)';
				}
				const cont = that.$refs.continue;
				if (cont) {
					cont.style.transition = 'transform 0.5s ease';
					cont.style.transform = 'translateX(-'+cont.clientWidth+'px)';
				}
				that.imageHide = true;
				setTimeout(function () {
					if (watch) {
						watch.style.transform = 'translateX(0)';
					}
					if (cont) {
						cont.style.transform = 'translateX(0)';
					}
					that.imageHide = false;
				}, 3000)
			}

			window.clearTimeout(that.isScrolling);
			that.isScrolling = setTimeout(function() {
				const element = that.$refs.rank;
				if (that.isElementInViewport(element)) {
					console.log('触发播放');
					that.videoPlay();
				} else {
					console.log('触发暂停');
					that.videoPause();
				}
			}, 200);

			if (that.$util.isReachBottom() && that.hasMoreData && !that.isLoading) {
				console.log('加载更多数据')
				that.loadListData(false);
			}
		};
		document.addEventListener('scroll', this.scrollListener);
	},
	beforeDestroy() {
		if (this.scrollListener) {
			document.removeEventListener('scroll', this.scrollListener);
			console.log('document.removeEventListener(\'scroll\', this.scrollListener)')
		}
		const leaveTime = new Date().getTime();
		const stayTime = leaveTime - this.enterTime;
		this.$util.pageStopover(this.path, Math.floor(stayTime / 1000));
	},
	methods: {
		onSwiper(swiper) {
			console.log(swiper);
		},
		onSwiperChange() {
			console.log('slide change');
		},
		videoPlay() {
			// const swiperInstance = this.$refs.mySwiper.swiper;
			// const currentIndex = swiperInstance.activeIndex;
			// const videoPlayer = this.$refs[`videoRef${currentIndex}`];
			// if (this.playerOptions.sources[0].src && videoPlayer[0] && videoPlayer[0].player) {
			// 	videoPlayer[0].player.play();
			// }
		},
		videoPause() {
			const swiperInstance = this.$refs.mySwiper.swiper;
			const currentIndex = swiperInstance.activeIndex;
			const videoPlayer = this.$refs[`videoRef${currentIndex}`];
			if (this.playerOptions.sources[0].src && videoPlayer[0] && videoPlayer[0].player) {
				videoPlayer[0].player.pause();
			}
		},
		toDetail(e) {
			console.log(e)
			console.log(e.currentTarget.dataset.id)
			this.$router.push({
				path: '/detail',
				query: {
					plet_id: e.currentTarget.dataset.id
				}
			})
		},
		loadUserLastRecord() {
			let that = this;
			that.$util.axios.get('api/applet/v1/user/last/record', {}).then(function (res) {
				if (res.data) {
					that.last = res.data.info;
				}
			}).catch(function (error) {
				console.log('last获取失败', error)
			});
		},
		loadListData(reset) {
			let that = this;
			if (!reset) {
				that.$util.showLoading()
			}
			that.isLoading = true;
			let page = reset ? 1 : that.pageNumber;
			let loading = page > 1;
			that.$util.axios.get('api/applet/v2/playlet/list?sort=0&limit=10&page='+page, {}).then(function (res) {
				if (res.code == 100) {
					let list = that.list;
					if (res.data.list.length > 0) {
						if (page > 1) {
							res.data.list.forEach(function (item) {
								list.push(item);
							});
						} else {
							list = res.data.list;
						}
						that.list = list;
						that.pageNumber = page+1;
						that.isLoading = false;
						that.hasMoreData = true;
					} else {
						console.log('没有更多了')
						that.isLoading = false;
						that.hasMoreData = false;
					}
				}
			}).catch(function (error) {
				console.log('列表获取失败', error)
				that.isLoading = false;
			});
		},
		videoClick() {
			console.log(123123)
		},
		videoPlaying() {
			let that = this;
			that.videoCoverView = true;
		},
		toList(e) {
			this.$router.push({
				path: '/list',
				query: {
					t: e.currentTarget.dataset.type
				}
			})
		},
		toSearch() {
			this.$router.push({
				path: '/search'
			})
		},
		toWelfare() {
			this.changeTabFunc(2);
		},
		isElementInViewport(el) {
			const rect = el.getBoundingClientRect();

			return (
					rect.top >= 0 &&
					rect.left >= 0 &&
					rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
					rect.right <= (window.innerWidth || document.documentElement.clientWidth)
			);
		}
	},
}
</script>
<style scoped>
.index {
	background: #F7F7F7;
	padding-top: 0.36rem;
	background-image: url("https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/index/background.png");
	background-repeat: no-repeat;
	margin: 0 auto;
}

.search {
	text-align: center;
	position: relative;
	width: 6.88rem;
	height: 0.72rem;
	background: #FFFFFF;
	border-radius: 0.36rem;
	margin: 0 auto 0.26rem;
}
.search .search-button {
	position: absolute;
	top: 0.20rem;
	left: 0.28rem;
	width: 0.32rem;
	height: 0.34rem;
}
.search span {
	display: block;
	position: absolute;
	left: 0.78rem;
	height: 0.72rem;
	font-size: 0.26rem;
	color: #999999;
	line-height: 0.72rem;
}

.banner .swiper-slide {
	width: 6.98rem;
	height: 2.88rem;
	border-radius: 0.14rem;
	overflow: hidden;
}
.banner img {
	width: 6.98rem;
	height: auto;
	border-radius: 0.14rem;
}

.toolbar-list {
	margin: 0.38rem 0.26rem 0.30rem;
	display: flex;
	justify-content: space-between;
}
.toolbar-list img {
	display: block;
	width: 1.64rem;
	height: 2.08rem;
}


.hot {
	width: 7.02rem;
	height: 4.34rem;
	background: #FFFFFF;
	border-radius: 0.16rem;
	margin: 0 auto 0.20rem;
	padding: 0.22rem 0 0 0.24rem;
	box-sizing: border-box;
}
.hot .hot-name {
	font-weight: 600;
	font-size: 0.34rem;
	color: #333333;
	text-align: left;
}
.hot .teleplay-list {
	margin-top: 0.18rem;
	display: flex;
	justify-content: flex-start;
	white-space: nowrap;
	height: 3.24rem;
	overflow: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
}
.hot .teleplay-list::-webkit-scrollbar {
	display: none !important;
	width: 0 !important;
	height: 0 !important;
	-webkit-appearance: none !important;
	background: transparent !important;
	color:transparent !important;
}
.hot .teleplay-list .teleplay {
	width: 2.04rem;
	margin-right: 0.20rem;
	display: inline-block;
}
.hot .teleplay-list .teleplay img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
}
.hot .teleplay-list .teleplay .title {
	height: 0.36rem;
	width: 2.04rem;
	overflow: hidden;
	margin-top: 0.12rem;
	font-size: 0.26rem;
	color: #333333;
	font-weight: 400;
}

.img-container {
	width: 2.04rem;
	height: 2.72rem;
	border-radius: 0.10rem;
	overflow: hidden;
	position: relative;
}


.rank {
	position: relative;
	width: 7.02rem;
	height: 4.42rem;
	background-image: url("https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/index/rank-bg.png");
	background-size: 100%, auto;
	background-repeat: no-repeat;
	margin: 0 auto;
	box-sizing: border-box;
	padding-top: 0.22rem;
	overflow: hidden;
	margin-bottom: 0.36rem;
}
.rank .rank-name {
	text-align: left;
	font-size: 0.34rem;
	font-weight: 600;
	color: #333333;
	margin-left: 0.24rem;
}
.rank .swiper-slide {
	display: flex;
	align-items: center;
}
.rank .swiper {
	margin-top: 0.30rem;
	height: 2.84rem;
}
.rank .rank-left-cover {
	height: 6.6rem;
	width: 0.32rem;
	position: absolute;
	left: 0;
	top: 0;
	background-color: #FAFAFC;
	z-index: 100;
}
.rank .rank-right-cover {
	height: 6.6rem;
	width: 0.32rem;
	position: absolute;
	right: 0;
	top: 0;
	background-color: #FAFAFC;
	z-index: 100;
}
.rank .teleplay img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
}
.rank .img-container {
	width: 100%;
	height: 2.38rem;
	overflow: hidden;
	position: relative;
	border-radius: 0.12rem;
	margin-top: 0.46rem;
}
.rank .video, .video-js {
	width: 100%;
	height: 100%;
	border-radius: 0.12rem;
	overflow: hidden;
}
.vjs-big-play-button {
	position: absolute!important;
	top: 50%!important;
	left: 50%!important;
	transform: translate(-50%, -50%);
}
.video-js .vjs-big-play-button {
	width: 50px!important;
	height: 50px!important;
	border-radius: 50%!important;
	border: none!important;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3)!important;
	font-size: 24px!important;
	line-height: 50px!important;
	color: #fff;
}
.video-js .vjs-volume-panel {
	display: none !important;
}
.rank .cover-view{
	width: 100%;
	height: 3rem;
	position: absolute;
	top: 0;
	overflow: hidden;
}

.all {
	margin: 0 0.24rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: flex-start;
	padding-bottom: calc(0.6rem + env(safe-area-inset-bottom));
}
.all:after {
	content: "";
	width: 2.12rem;
}
.all>.title {
	width: 100%;
	display: block;
	font-weight: 600;
	font-size: 0.32rem;
	color: #333333;
	margin-bottom: 0.20rem;
}
.all .teleplay {
	margin-bottom: 0.22rem;
}
.all .teleplay img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
}
.all .img-container {
	width: 2.12rem;
	height: 2.82rem;
	border-radius: 0.10rem;
	overflow: hidden;
	position: relative;
}
.all .teleplay .title {
	width: 2.12rem;
	height: 0.40rem;
	overflow: hidden;
	margin-top: 0.14rem;
	font-weight: 400;
	font-size: 0.26rem;
	color: #333333;
	text-align: left;
}

.watch {
	position: fixed;
	width: 1.64rem;
	bottom: 2.00rem;
	right: 0.20rem;
	transition: transform 0.5s ease;
	z-index: 500;
}
.watch img {
	display: block;
	width: 1.64rem;
	height: 1.50rem;
}

.continue {
	position: fixed;
	width: 1.84rem;
	height: 3.08rem;
	bottom: 2.00rem;
	left: 0.18rem;
	background-size: 1.84rem, 3.08rem;
	background-color: #56E9B1;
	z-index: 500;
}
.continue .img-container {
	width: 1.72rem;
	height: 2.64rem;
	margin: 0.06rem auto 0;
	border-radius: unset;
	overflow: hidden;
	position: relative;
}
.continue .continue-cover {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
}
.continue .continue-button {
	display: block;
	width: 1.84rem;
	height: 0.46rem;
	line-height: 0.46rem;
	background: linear-gradient( 124deg, #56E9B1 0%, #56E8B0 100%);
	font-weight: 400;
	font-size: 0.24rem;
	color: #FFFFFF;
	text-align: center;
	border-radius: 0.08rem;
}
.continue-play {
	position: absolute;
	left: 0.64rem;
	top: 1.28rem;
	width: 0.52rem;
	height: 0.52rem;
}


</style>